import * as Yup from 'yup';

import IFormField from '../models/form-field.model';
import { IClientLegalConsentTemplate } from '../models/client-legal-consent-template.model';
import { IOption } from '../models/option.model';
import useTranslations from '../hooks/use-translations';

const MAX_CONTENT_LENGTH = 500;
const CONTENT_ROWS = 7;

export interface IClientLegalConsentTemplateValues {
    language: string;
    name: string;
    content: string;
}

export function getClientLegalConsentTemplateInitialValues(
    template?: IClientLegalConsentTemplate
): IClientLegalConsentTemplateValues {
    return {
        language: template?.language || '',
        name: template?.name || '',
        content: template?.content || '',
    };
}

export function getClientLegalConsentTemplateValidationSchema(
    t: ReturnType<typeof useTranslations<'ClientLegalConsentTemplateForm'>>
) {
    return Yup.object({
        language: Yup.string().required(t.language.error.required),
        name: Yup.string().required(t.name.error.required),
        content: Yup.string()
            .max(MAX_CONTENT_LENGTH, `${t.content.error.max}${MAX_CONTENT_LENGTH}`)
            .required(t.content.error.required),
    });
}

export function getClientLegalConsentTemplateFields(
    t: ReturnType<typeof useTranslations<'ClientLegalConsentTemplateForm'>>,
    languagesOptions: IOption[]
): IFormField[] {
    return [
        {
            name: 'language',
            type: 'select',
            hint: t.language.hint,
            placeholder: t.language.placeholder,
            isRequired: true,
            isClearable: false,
            options: languagesOptions,
        },
        {
            name: 'name',
            type: 'text',
            placeholder: t.name.placeholder,
            isRequired: true,
        },
        {
            name: 'content',
            type: 'textarea',
            placeholder: t.content.placeholder,
            isRequired: true,
            maxLength: MAX_CONTENT_LENGTH,
            rows: CONTENT_ROWS,
        },
    ];
}
